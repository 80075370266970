import React, { useState } from "react";
import "../Styles/Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import logo from "/webapps/templete002/src/Assets/Contaqa 1.png";

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleAboutClick = () => {
    navigate("/");
    setTimeout(() => {
      const aboutSection = document.getElementById("about");
      if (aboutSection) {
        aboutSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 0); // Delay to ensure navigation completes
  };


  const handleserviceClick = () => {
    navigate("/");
    setTimeout(() => {
      const aboutSection = document.getElementById("Servicesss");
      if (aboutSection) {
        aboutSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 0); // Delay to ensure navigation completes
  };

  const handleprojectsClick = () => {
    navigate("/");
    setTimeout(() => {
      const aboutSection = document.getElementById("projects");
      if (aboutSection) {
        aboutSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 0); // Delay to ensure navigation completes
  };

  return (
    <div className="navbar-wrapper">
      <nav className="navbar navbar-expand-lg navbar-light container">
        <div className="container-fluid">
          <Link to="/" style={{ textDecoration: "none" }}>
            <a className="navbar-brand" href="#">
              <img
                src={logo}
                alt="Logo"
                className="contaqa-logo"
/>
            </a>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleMenu}
            aria-controls="navbarSupportedContent"
            aria-expanded={isMenuOpen}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex">
              <ScrollLink
                to="about"
                smooth={true}
                duration={1000}
                onClick={toggleMenu}
              >
              <li className="nav-item" onClick={handleAboutClick}>
                  <a className="nav-link active" aria-current="page" href="#">
                    About Us{" "}
                  </a>
                </li>
              </ScrollLink>
              <ScrollLink
                to="Servicesss"
                smooth={true}
                duration={1000}
                onClick={toggleMenu}
              >
              <li className="nav-item" onClick={handleserviceClick}>
                  <a className="nav-link active" aria-current="page" href="#">
                    Services{" "}
                  </a>
                </li>
              </ScrollLink>
              <ScrollLink
                to="projects"
                smooth={true}
                duration={1000}
                onClick={toggleMenu}
              >
              <li className="nav-item" onClick={handleprojectsClick}>
                  <a className="nav-link active" aria-current="page" href="#">
                    Projects
                  </a>
                </li>
              </ScrollLink>
              <Link
                to="/ContactUS"
                style={{ textDecoration: 'none' }}
              >
                <li className="nav-item" >
                  <a className="nav-link active" aria-current="page" href="#" >
                    Contact Us
                  </a>
                </li>
              </Link>
            </ul>
            {/* <ul className="navbar-nav ml-auto" style={{ marginLeft: "auto" }}>
              <li className="nav-item" id="signup-holder">
                <Link to="/Signup">
                  <button className="signup-btn" href="#">
                    Sign Up{" "}
                  </button>
                </Link>
              </li>
            </ul> */}
          </div>
        </div>
      </nav>
    </div>
  );
}
