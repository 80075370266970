import React from "react";
import bannerhuman from "/webapps/templete002/src/Assets/Group 1906 (1).png";
import "../Styles/Banner.css";

export default function Banner() {
  return (
    <div className="wrap">
      <div className="container GridWrap">
        <div className="left-Banner-sec">
          <h1 className="Banner-header">
          CONTAQA … “Be Energetic!”
          </h1>
          <p className="Banner-paragraph">
            CONTAQA is an Industrial Electrical, Instrumentation and Automation Contractor, operating in the power generation, oil, gas and other process sectors. We offer Engineering , Procurement , Construction , Commissioning , Operation , Maintenance and Technical Manpower Outsourcing Services to our Clients.
          </p>
          <div class="card-flex">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">5 +</h5>
                <p class="card-text">Services</p>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">20 +</h5>
                <p class="card-text">Projects</p>
              </div>
            </div>
          </div>
        </div>
        <div className="right-Banner-sec">
          <img src={bannerhuman} />
        </div>
      </div>
    </div>
  );
}
