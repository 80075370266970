import React from 'react'
import '/webapps/templete002/src/Styles/Partner.css'
import partner1 from "/webapps/templete002/src/Assets/image (1).png";
import partner2 from "/webapps/templete002/src/Assets/image 1.png";
import partner3 from "/webapps/templete002/src/Assets/image 3.png";
import partner4 from "/webapps/templete002/src/Assets/image 4.png";
import partner5 from "/webapps/templete002/src/Assets/image 5.png";
import partner6 from "/webapps/templete002/src/Assets/image 6.png";
import partner7 from "/webapps/templete002/src/Assets/image 7.png";
import partner8 from "/webapps/templete002/src/Assets/image 8.png";
import partner9 from "/webapps/templete002/src/Assets/image 9.png";
import partner10 from "/webapps/templete002/src/Assets/image 10.png";
import partner11 from "/webapps/templete002/src/Assets/image 11.png";
import partner12 from "/webapps/templete002/src/Assets/image 12.png";

export default function Partners() {
    return (
        <div className='Partner-Wrap'>
            <div className='Partner-container container'>
                <div className='Partner-title'>
                    <h2>Our Trusted Partners</h2>
                    <p>We Will Provide you with the Most Qualified, Motivated <br /> Candidates When
                        And Where You Need Them At A  <br /> Competitive Price</p>
                </div>
                <div className='Partner-grid'>
                    <div className='Partner-grid-element'>
                        <img src={partner1} className='partner-img-auto' />
                    </div>
                    <div className='Partner-grid-element'>
                        <img src={partner2} className='partner-img-auto' />
                    </div>
                    <div className='Partner-grid-element'>
                        <img src={partner3} className='partner-img-auto' />
                    </div>
                    <div className='Partner-grid-element'>
                        <img src={partner4} className='partner-img-auto' />
                    </div>
                    <div className='Partner-grid-element'>
                        <img src={partner5} className='partner-img-auto' />
                    </div>
                    <div className='Partner-grid-element'>
                        <img src={partner6} className='partner-img-auto' />
                    </div>
                    <div className='Partner-grid-element'>
                        <img src={partner7} className='partner-img-auto' />
                    </div>
                    <div className='Partner-grid-element'>
                        <img src={partner8} className='partner-img-auto' />
                    </div>
                    <div className='Partner-grid-element'>
                        <img src={partner9} className='partner-img-auto' />
                    </div>
                    <div className='Partner-grid-element'>
                        <img src={partner10} className='partner-img-auto' />
                    </div>
                   
                </div>
                <div className='grid-2'>
                <div className='Partner-grid-element'>
                        <img src={partner11} className='partner-img-auto' />
                    </div>
                    <div className='Partner-grid-element'>
                        <img src={partner12} className='partner-img-auto' />
                    </div>

                </div>
            </div>
        </div>
    )
}
