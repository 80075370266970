import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";
import "/webapps/templete002/src/Styles/Contactus.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import banner2 from "/webapps/templete002/src/Assets/Rectangle 197.png";

const ContactUS = () => {
    const navigate = useNavigate();

    const [userEmail, setUserEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [capVal, setCapVal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false); // State to manage submission status

    function onChange(value) {
        console.log("captcha value", value);
        setCapVal(true);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (userEmail && subject && message) {
            setIsSubmitting(true); // Start submitting
            emailjs
                .send(
                    "service_ws980i9",
                    "template_oi28phw",
                    { from_name: userEmail, subject, message },
                    "-jrdgHpYNxTi55stL"
                )
                .then((response) => {
                    setUserEmail("");
                    setSubject("");
                    setMessage("");
                    Swal.fire({
                        title: "Thank you",
                        text: "Email has been successfully sent",
                        icon: "success",
                    }).then(() => {
                        setTimeout(() => {
                            navigate("/", { replace: true });
                            window.location.reload();
                        }, 3000);
                    });
                })
                .catch((error) => {
                    console.error("Error sending email:", error);
                    Swal.fire({
                        title: "Error",
                        text: "Error sending email. Please try again later.",
                        icon: "error",
                    });
                });
        } else {
            Swal.fire({
                title: "Error",
                text: "Please fill in all fields.",
                icon: "error",
            });
        }
    };

    return (
        <div id="root">
            <Navbar />
            <section>
                <div className="section-header">
                    <div className="banner-contact-us">
                        <div className="banner-content">
                            <h2>Contact Us</h2>
                            <p>
                                To contact us for electrical engineering solutions, feel free to
                                message us via the details below. We are here to serve you!
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container wrap-container-contact">
                    <div className="contact-form-new">
                        <form action="" id="contact-form" onSubmit={handleSubmit}>
                            <div className="titles-for-contact-us">

                                <h2>Send us a message</h2>
                                <p>
                                    Please enter your details below to send your message, and we will respond as soon as possible.
                                </p>
                            </div>
                            <div className="flex-1">
                                <div className="input-box">
                                    <input class="form-control" type="email" required value={userEmail} name="user_email" placeholder='Email' onChange={(e) => setUserEmail(e.target.value)} />
                                </div>
                                <div className="input-box">
                                    <input class="form-control" type="text" required value={subject} name="subject" placeholder='Subject' onChange={(e) => setSubject(e.target.value)} />
                                </div>
                            </div>

                            <div className="input-box">
                                <textarea style={{ minHeight: '180px' }} class="form-control" required value={message} name="message" placeholder='Body' onChange={(e) => setMessage(e.target.value)}></textarea>
                            </div>

                            <div className="recaptcha-container">
                                    <ReCAPTCHA
                                        sitekey="6Ldn-UcqAAAAACD1TozdS1spUNhFv3GMxRlY3LgK" // Your site key here
                                        onChange={onChange}
                                    />
                                </div>

                            <div className="input-box-val">
                                <input disabled={!capVal} className="send-btn-003" required type="submit"  value={isSubmitting ? "Sending..." : "Send Message"} />
                            </div>

                            
                        </form>
                                      </div>

                </div>
                
                {/* <div className="container">
                    <div className="contactrow row">
                        <div className="contact-form">
                            <form action="" id="contact-form" onSubmit={handleSubmit}>
                                <h2>Send Email</h2>
                                <div className="input-box">
                                    <input type="email" required value={userEmail} name="user_email" placeholder='Email' onChange={(e) => setUserEmail(e.target.value)} />
                                </div>

                                <div className="input-box">
                                    <input type="text" required value={subject} name="subject" placeholder='Subject' onChange={(e) => setSubject(e.target.value)} />
                                </div>

                                <div className="input-box">
                                    <textarea required value={message} name="message" placeholder='Body' onChange={(e) => setMessage(e.target.value)}></textarea>
                                </div>
{/* 
                                <div className="recaptcha-container">
                                    <ReCAPTCHA
                                        sitekey="6LfaIiIqAAAAAOtahu5fA-gofl7H_FIrUFRL13yN" // Your site key here
                                        onChange={onChange}disabled={!capVal}
                                    />
                                </div> */}

                {/* <div className="input-box">
                                    <input  required type="submit" disabled={isSubmitting}  value={isSubmitting ? "Sending..." : "Send"} />
                                </div>
                            </form>
                        </div>
                    </div>
                </div> */}
            </section>
            <div className="banner-content5">
                            <img src={banner2} />
                        </div>   
            <Footer />
        </div>
    );
};

export default ContactUS;
