import React from "react";
import "../Styles/Services.css";
import AboutIMG from "/webapps/templete002/src/Assets/co-workers-planning-strategy 1 (1).png";
import card1 from "/webapps/templete002/src/Assets/Group 1922.png";
import card2 from "/webapps/templete002/src/Assets/Group 1923.png";
import card3 from "/webapps/templete002/src/Assets/Group 1924.png";
import card4 from "/webapps/templete002/src/Assets/Group 1921.png";
import card5 from "/webapps/templete002/src/Assets/Group 1920.png";

export default function Services() {
  return (
    <div className="About-wrap" id="Servicesss">
      <div className="titles-mid">
        <h6 className="About">Our Services</h6>
        <h2 className="text-1">The Projects Presented Represent a Diverse Range Of Innovative Solutions</h2>
        {/* <h5 className="text-2">
          {" "}
          There is a comprehensive system for managing your wealth
        </h5> */}

            <div className="grid-services-003 container">

                <div className="element-grid-service-again">
                    <img src={card1} className="img-for-sigle-service" />
                </div>
                <div className="element-grid-service-again">
                    <img src={card2} className="img-for-sigle-service" />
                </div>
                <div className="element-grid-service-again">
                    <img src={card3} className="img-for-sigle-service" />
                </div>
                <div className="element-grid-service-again">
                    <img src={card4} className="img-for-sigle-service" />
                </div>
                <div className="element-grid-service-again">
                    <img src={card5} className="img-for-sigle-service" />
                </div>

            </div>

      </div>


    </div>
  );
}
