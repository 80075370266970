import React from 'react'
import footer from "/webapps/templete002/src/Assets/Contaqa 1.png"
import "../Styles/Footer.css";
import email from "/webapps/templete002/src/Assets/email (2) 1 (1).png"
import global from "/webapps/templete002/src/Assets/globe.png"
import phone from "/webapps/templete002/src/Assets/phone (1).png"

export default function Footer() {
  return (
    <div className='footer' id='footer'>

        <div className="container mid-sec">
            <div className='img-sec'>
            <img src={footer} className="contaqa-logo" />
            <h6>143, Al-Moltaka Al-Araby, Al-Nasr Road , Sheraton <br/> Al-Matar, Al-Nozha , Cairo, Egypt</h6>
            </div>
            {/* <div className='pull-1'>
            <h6>Follow Us</h6>                
            <img src={facebook} className='footer-img'/>
            <div className='pull-2' style={{float:"right" , marginTop:"-2.7rem"}}>

            <h6>Lets Talk</h6>                
            <img src={email} className='footer-img'/>

                
            </div>



            </div> */}

            <div className='flexing-footer'>

              <div className='email-footer footer-flex-element'>
                <img  src={email} />
                <span> info@Contaqa.com</span>

              </div>

              <div className='phone-footer footer-flex-element'>
              <img  src={phone} />
                <span> +20 12 2173 4847 </span>

              </div>

              <div className='website-url-footer footer-flex-element '>
              <img  src={global} />
                <span> www.Contaqa.com </span>

              </div>


            </div>



        </div>
        {/* <div class="container">
        <div class="row">
          <div class="col-md-8 col-sm-6 col-xs-12">
          <div className="footer-line"></div>
      <div className="footer-content">
        Copyright © {new Date().getFullYear()} 
      </div>
          </div>

         
        </div>
        </div> */}

    </div>
  )
}
