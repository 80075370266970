import React from "react";
import "../Styles/AboutCompany.css";
import AboutIMG from "/webapps/templete002/src/Assets/co-workers-planning-strategy 1 (1).png";
import card1 from "/webapps/templete002/src/Assets/target 2.png";
import card2 from "/webapps/templete002/src/Assets/accounting (3) 1 (1).png";
import card3 from "/webapps/templete002/src/Assets/Group 2 (2).png";

export default function AboutCompany() {
  return (
    <div className="About-wrap" id="about">
      <div className="titles-mid">
        <h6 className="About">About Company</h6>
        <h2 className="text-1">We are committed to providing “Service <br /> Excellence” to the
          candidates</h2>
        {/* <h5 className="text-2">
          {" "}
          There is a comprehensive system for managing your wealth
        </h5> */}
      </div>

      <div className="About-layout container">
        <div className="About-img">
          {" "}
          <img src={AboutIMG} />
        </div>

        <div className="Finincial-items">
          <div class="card2 mt-3">
            <div class="row g-0">
              <div class="col-md-1">
                <img src={card1} class="img-fluid002" alt="..." />
              </div>
              <div class="col-md-11">
                <div class="card-body">
                  <h5 class="card-title2">Our Vision: </h5>

                  <p class="card-text2">
                    To be the recognized multi-national company in the field of Electrical, Instrumentation and Process Control; in the Egyptian, MENA and African markets.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="card2 ">
            <div class="row g-0">
              <div class="col-md-1">
                <img src={card1} class="img-fluid002" alt="..." />
              </div>
              <div class="col-md-11">
                <div class="card-body">
                  <h5 class="card-title2">Our Mission:</h5>

                  <p class="card-text2">
                  • Helping Customers to work better, faster and more efficiently by providing them with top
                    of the line service through our talented teams at the right time to make and maintain their
                    business productive, successful.
                  </p>
                  <p class="card-text2 pt-2">
                  • Helping candidates to live the life they love by providing them with fulfilling career
                    opportunities that utilize their unique talents and skills, while driving them on toward greater
                    success.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div class="card2 ">
            <div class="row g-0">
              <div class="col-md-1">
                <img src={card3} class="img-fluid002 " alt="..." />
              </div>
              <div class="col-md-11">
                <div class="card-body">
                  <h5 class="card-title2">Financial planning and budgeting</h5>

                  <p class="card-text2">
                    Financial software provides tools for planning budgets and
                    monitoring expenses and revenues. Through it you can:
                    Budgeting: Determine the available financial resources and
                    distribute them to different
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
