import React, { useState } from "react";
import "../Styles/Projects.css";
import project1 from "/webapps/templete002/src/Assets/image (2).png";
import project2 from "/webapps/templete002/src/Assets/image (3).png";
import project3 from "/webapps/templete002/src/Assets/Rectangle 158 (4).png";

import project4 from "/webapps/templete002/src/Assets/IMAG0367.jpg";
import project5 from "/webapps/templete002/src/Assets/IMG-20170616-WA0020.jpg";
import project6 from "/webapps/templete002/src/Assets/IMAG1231.jpg";
import project7 from "/webapps/templete002/src/Assets/IMAG1198.jpg";
import project8 from "/webapps/templete002/src/Assets/IMG-20170616-WA0008.jpg";
import project9 from "/webapps/templete002/src/Assets/IMAG0960.jpg";
import project10 from "/webapps/templete002/src/Assets/IMAG0049.jpg";

export default function Projects() {


  const [showMore, setShowMore] = useState(false);

  // Array containing the projects data
  const projects = [
    { id: 1, img: project1, description: "• Engineering Support for DCS Upgrade \n • Onshore Commissioning for DCS Upgrade." },
    { id: 2, img: project2, description: "Water Plant Instruments Calibration" },
    { id: 3, img: project3, description: "BoP, HRSG and Turbine Instruments \n • Calibration and Commissioning. \n • Testing of GE T60 Overall Relays." },
    { id: 4, img: project4, description: "DCS,ESD and F&G Systems Commissioning" },
    { id: 5, img: project5, description: "SCADA Control System Upgrade" },
    { id: 6, img: project6, description: "Control System Commissioning and Startup of Plant" },
    { id: 7, img: project7, description: "Instruments Calibration and Commissioning." },
    { id: 8, img: project8, description: "Unit 2 Instruments Calibration and Commissioning." },
    { id: 9, img: project9, description: "Installation and Commissioning of Cement Packing Machines" },
    { id: 10, img: project10, description: "Construction and Commissioning for 220kV Substation." }
  ];

  const visibleProjects = showMore ? projects : projects.slice(0, 3);

  return (
    <div className="Projects-wrap" id="projects">
      <div className="titles-mid">
        <h6 className="About">Our Projects</h6>
      </div>
      <div className="Projects-grid container">
        {visibleProjects.map((project) => (
          <div key={project.id} className="card4">
            <img src={project.img} className="card-img-top5" alt="Project" />
            <div className="card-body"style={{ whiteSpace: 'pre-line' }}>
              <p className="card-text" style={{ whiteSpace: 'pre-line' }}>{project.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="show-more-container container">
        <button
          className="show-more-btn"
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? "Show Less >" : "Show More >"}
        </button>
      </div>
    </div>
  );
}
