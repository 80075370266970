import React from 'react'
import Navbar from './Navbar'
import Banner from './Banner'
import AboutCompany from './AboutCompany'
import Services from './Services'
import Projects from './Projects'
import Footer from './Footer'
import Partners from './Partners'
import Values from './Values'

export default function Home() {
  return (
    <div>
        <Navbar />
        <Banner/>
        <Partners/>
        <AboutCompany />
        <Values />
        <Services/>
        <Projects />
        <Footer/> 
    </div>
  )
}
