import React from "react";
import "../Styles/Values.css";
import blue from "/webapps/templete002/src/Assets/Rectangle 4 (2).png";
import third from "/webapps/templete002/src/Assets/dde41634d384ecb7e29e1a56357d9d43 1.png";
import work1 from "/webapps/templete002/src/Assets/co-workers-with-pens-pointing-bar-chart 1 (1).png";
import work2 from "/webapps/templete002/src/Assets/positive-woman-showing-tablet-screen-business-people 1 (1).png";
import work3 from "/webapps/templete002/src/Assets/dde41634d384ecb7e29e1a56357d9d43 1 (3).png";
import work4 from "/webapps/templete002/src/Assets/co-workers-with-pens-pointing-bar-chart 1 (2).png";
import work5 from "/webapps/templete002/src/Assets/positive-woman-showing-tablet-screen-business-people 1 (2).png";
import work6 from "/webapps/templete002/src/Assets/dde41634d384ecb7e29e1a56357d9d43 2.png";
import work7 from "/webapps/templete002/src/Assets/positive-woman-showing-tablet-screen-business-people 2.png";
import work8 from "/webapps/templete002/src/Assets/positive-woman-showing-tablet-screen-business-people 3 (1).png";

export default function Values() {
  return (
    <div className="Service-wrap" >
      <div className="titles-mid">
        <h6 className="About">Our Values</h6>
        <h2 className="text-003">
          CONTAQA is an Egyptian Privately Owned Company And <br /> Operating in Egypt, Nigeria and
          Kazakhstan, We Also <br /> Deliver Our Services in Africa and MENA Region
        </h2>
      </div>

      <div className="cards-holder container ">
        <div className="card3">
          <img src={work1} alt="First" className="image-full" />
          <div className="image-overlay">
            <img src={blue} alt="Second" className="image-partial" />
            <div className="overlay-text">Customer Focus:</div>
            <p className="overlay-paragraph">Deliver what is promised and strive to add value beyond what is expected.</p>
          </div>
        </div>
        <div className="card3">
          <img src={work2} alt="First" className="image-full" />
          <div className="image-overlay">
            <img src={blue} alt="Second" className="image-partial" />
            <div className="overlay-text">Integrity:</div>
            <p className="overlay-paragraph3">Base relationship on trust, doing the right thing when dealing with people and business issues, and communicate openly and honestly.</p>

          </div>
        </div>
        <div className="card3">
          <img src={work3} alt="First" className="image-full" />
          <div className="image-overlay">
            <img src={blue} alt="Second" className="image-partial" />
            <div className="overlay-text">Respect:</div>
            <p className="overlay-paragraph3">Treat people with dignity and promote an environment in which people from diverse backgrounds and lifestyle can thrive.</p>

          </div>
        </div>
        <div className="card3">
          <img src={work4} alt="First" className="image-full" />
          <div className="image-overlay">
            <img src={blue} alt="Second" className="image-partial" />
            <div className="overlay-text">Teamwork: </div>
            <p className="overlay-paragraph">Collaborate to share knowledge, solve problems and build strong team.</p>

          </div>
        </div>
        <div className="card3">
          <img src={work5} alt="First" className="image-full" />
          <div className="image-overlay">
            <img src={blue} alt="Second" className="image-partial" />
            <div className="overlay-text">Safety: </div>
            <p className="overlay-paragraph2">Make sure everyone back home safe while maintaining the environment and workplace</p>

          </div>
        </div>
        <div className="card3">
          <img src={work6} alt="First" className="image-full" />
          <div className="image-overlay">
            <img src={blue} alt="Second" className="image-partial" />
            <div className="overlay-text">Simplicity & Flexibility:</div>
            <p className="overlay-paragraph3">Makes us communicate and act quickly and decisively to get things
done while maintaining the very efficient and positive attitude.</p>
          </div>
        </div>
        <div className="card3">
          <img src={work7} alt="First" className="image-full" />
          <div className="image-overlay">
            <img src={blue} alt="Second" className="image-partial" />
            <div className="overlay-text">Continuous improvement: </div>
            <p className="overlay-paragraph2">Embrace changes and promote innovation and creativity in the
pursuit of higher performance</p>
          </div>
        </div>
        <div className="card3">
          <img src={work8} alt="First" className="image-full" />
          <div className="image-overlay">
            <img src={blue} alt="Second" className="image-partial" />
            <div className="overlay-text">Service Excellence:</div>
            <p className="overlay-paragraph">Is the uncompromising standard that defines the way we do business</p>
          </div>
        </div>
      </div>
    </div>
  );
}
