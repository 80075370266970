import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './Components/Home';
import ContactUS from '/webapps/templete002/src/Components/Contactus.jsx'
function App() {
  return (
    <div>
      <Routes>
  

        <Route path="/" element={ <Home />}> </Route>
        <Route path="/Contactus" element={ <ContactUS />}> </Route>


      </Routes>
</div>
  );
}

export default App;